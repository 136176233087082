<style lang="css" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  padding: 0 100px;
}
.login .logo-box {
  margin: 40px auto;
}
.login .login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 487px;
  height: 486px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(57, 57, 57, 0.2);
  border-radius: 8px;
}
.login .login-btn {
  width: 390px;
  height: 60px;
  background-color: #3798ee;
  box-shadow: 1px 3px 6px 0px rgba(22, 77, 147, 0.18);
  border-radius: 6px;
  opacity: 0.8;
  font-size: 20px;
  margin-top: 30px;
}
.login .login-text {
  font-size: 14px;
  line-height: 26px;
  color: #939393;
}
.login >>> .login-input {
  box-sizing: border-box;
}
.login >>> .login-input .el-input__inner {
  /* width: 388px; */
  height: 56px;
  /* border-radius: 5px; */
}
.login >>> .el-form-item__content {
  line-height: 56px;
}
.login >>> .el-input__prefix {
  line-height: 56px;
  width: 30px;
}
.pick-code {
  display: flex;
  align-items: center;
  /* line-height: 60px; */
  justify-content: space-around;
}
@media screen and (max-width:1400px) {
   .login .login-bj img{
        width: 500px;
    }
   .login .login-box{
        width: 388px;
        height: 388px;
    }
    .login .login-box img{
        width: 120px;
    }
    .login >>> .login-input{
        width: 100%;
    }
    .login .login-btn{
        width: 100%;
        height: 50px;
        margin-top: 0;
    }
    .login .logo-box{
        margin: 30px auto;
    }
}
</style>

<template>
  <div class="login">
    <div class="login-bj">
      <img src="~@/assets/images/login_bj.png" alt="" />
    </div>
    <div class="login-box">
      <div class="logo-box">
        <img src="~@/assets/images/logo.png" alt="" />
      </div>
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-form-item prop="phone">
          <el-input
            type="tel"
            placeholder="请输入手机号"
            class="login-input"
            v-model="ruleForm.phone"
          >
            <i slot="prefix" class="iconfont icon-yonghu"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="yzm">
          <el-input
            placeholder="请输入短信验证码"
            v-model="ruleForm.yzm"
            class="login-input"
            @keyup.enter.native="toHome('ruleForm')"
          >
            <i slot="prefix" class="iconfont icon-yanzhengyanzhengma"></i>
            <template slot="append">
              <div
                @click="getCode"
                style="width: 70px; line-height: 54px; cursor: pointer"
              >
               获取验证码
              </div>
              <!-- <div v-else  style="width: 70px; line-height: 54px;">
                  {{count}}
              </div> -->
            </template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            class="login-btn"
            @click="toHome('ruleForm')"
            >登 录</el-button
          >
        </el-form-item>
      </el-form>
      <!-- <span class="login-text">未注册的手机号登录成功后将自动注册</span> -->
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="25%" @close="hanleclose">
      <div class="pick-code">
        <el-input
        @keyup.enter.native="getYzm"
          placeholder="图形验证码"
          class="login-input"
          v-model="captcha"
          autofocus
          style="width: 250px; margin-right: 20px"
        >
          <i slot="prefix" class="iconfont icon-yanzhengyanzhengma"></i>
        </el-input>
        <el-image :src="src" @click="getCode" style="cursor: pointer" ></el-image>
      </div>
      <div slot="footer" class="dialog-footer" > 
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getYzm" >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getLoginYzm, doLogin } from "@/api/base";
import {debounce} from "@/utils/fangdou"
import axios from "axios";

/* 登录流程 */
//1、用户输入手机号码 => 点击获取短信验证码 => 弹出图形验证码 =>成功发送请求，失败重新验证图片=> 倒计时，输入短信验证码，成功发送登录请求，获取token，失败提示重新输入
export default {
  name: "Login",
  data() {
    return {
      isShow: false,
      count:60,
      dialogVisible: false,
      src: "",
      captcha: "", //图片验证码
      ruleForm: {
        phone: null,
        yzm: null, //短信验证码
      },
      rules: {
        phone: [
          {
            required: true,
            min: 11,
            max: 11,
            message: "请输入手机号码",
            trigger: "blur",
          },
        ],
        yzm: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  methods: {
    /* 登录按钮 */
    toHome(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let phoneNumber = this.ruleForm.phone;
          let yzm = this.ruleForm.yzm;
          let res = await doLogin({ phoneNumber, yzm });
          if (res.code != 1) return this.$message.error(res.message);
          sessionStorage.setItem("token", res.dataSingle.sessionId);
          sessionStorage.setItem("phoneNumber", phoneNumber);
          sessionStorage.setItem("operation", res.dataSingle.operation);
          sessionStorage.setItem("owner", res.dataSingle.owner);
          sessionStorage.setItem("user", res.dataSingle.user);
          sessionStorage.setItem("patient", res.dataSingle.patient);

          //判断path是否报告query参数，有跳到指定页面 没有跳到home
          let toPath = this.$route.query.redirect || "/home"
          this.$router.replace(toPath);
        } else {
          return false;
        }
      });
    },
    /* 获取图片证码 */
    async getCode() {
      let phone = this.ruleForm.phone;
      if (!phone || phone.length != 11)
        return this.$message.error("请输入手机号码");
      this.dialogVisible = true;
      let res = await axios({
        method: "get",
        url: `https://report.inno-quick.com/api/biz/pc/getLoginCaptcha?phoneNumber=${phone}`,
        data: {},
        responseType: "arraybuffer",
      });
      let blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      this.src = url;
    },
    /*获取短信验证码*/
    getYzm:debounce(
    async function() {
      let phoneNumber = this.ruleForm.phone;
      let captcha = this.captcha;
      let res = await getLoginYzm({ phoneNumber, captcha });
      this.dialogVisible = false;
      if (res.code == -1) return this.$message.error(res.message);
      this.$message.success('短信发送成功')
    }
    ),
    //弹框关闭回调
    hanleclose(){
        this.captcha=""
    }
    /*倒计时*/
    // countdown() {
    //   this.isShow = true;
    //   let that = this
    //   let timer = setInterval(function () {
    //       that.count =that.count - 1
    //      if (that.count < 1) {
    //       clearInterval(timer);
    //         that.isShow= false
    //         that.count= 60
    //     }
    //   }, 1000);
    // },
  },
};
</script>


